import { defineComponent as _defineComponent } from 'vue';
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = {
    key: 0,
    class: "container-fluid"
};
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col" };
const _hoisted_5 = _createTextVNode(" Leave module ");
const _hoisted_6 = { class: "row h-100" };
const _hoisted_7 = { class: "col-12" };
const _hoisted_8 = ["src"];
const _hoisted_9 = {
    key: 1,
    class: "container py-4"
};
const _hoisted_10 = { class: "row card-sm mx-1 mx-sm-0" };
const _hoisted_11 = _createElementVNode("div", { class: "col-12 card-body" }, [
    _createElementVNode("h1", null, "Congratulations")
], -1);
const _hoisted_12 = { class: "col-md-12 card-body" };
const _hoisted_13 = { class: "col-md-12 card-body" };
const _hoisted_14 = _createElementVNode("p", null, " You can close this window using the button below. ", -1);
const _hoisted_15 = _createTextVNode(" Close window ");
const _hoisted_16 = {
    key: 2,
    class: "container py-4"
};
const _hoisted_17 = { class: "row card-sm mx-1 mx-sm-0" };
const _hoisted_18 = _createElementVNode("div", { class: "col-12 card-body" }, [
    _createElementVNode("h1", null, "Unfortunately this course cannot be completed at this time")
], -1);
const _hoisted_19 = { class: "col-md-12 card-body" };
const _hoisted_20 = _createElementVNode("p", null, " This module is not available for you to complete as you are currently absent from work. When you return to work, check to see if this module is still available via myLearning. ", -1);
const _hoisted_21 = _createElementVNode("p", null, " If the module is not available when you return, speak to your Store Manager to find out how to complete your required training modules. ", -1);
const _hoisted_22 = _createTextVNode(" Close window ");
const _hoisted_23 = { key: 1 };
const _hoisted_24 = { class: "container py-4" };
const _hoisted_25 = { class: "row card-sm mx-1 mx-sm-0" };
const _hoisted_26 = { class: "col-12 card-body" };
const _hoisted_27 = { class: "col-md-12 card-body" };
const _hoisted_28 = _createElementVNode("p", null, " If this module is still open in another tab/window you will need complete it or click the \"Leave module\" button before attempting to start another module. ", -1);
const _hoisted_29 = _createElementVNode("p", null, " If the tab/window is already closed click \"Continue module\" to open the module in this window. ", -1);
const _hoisted_30 = { class: "btn-group" };
const _hoisted_31 = _createTextVNode(" Continue module ");
const _hoisted_32 = _createTextVNode(" Re-check ");
import { onMounted, ref, watch } from "vue";
import BaseButton from "@/components/root/BaseButtonComponent.vue";
import myLearningService from "@/services/myLearning/myLearningCourseService";
import applicationService from "@/services/application/applicationService";
import authService from "@/services/application/authService";
import router from "@/router";
export default _defineComponent({
    __name: 'TakeCourseView',
    props: {
        courseId: null,
        employeeId: null
    },
    setup(__props) {
        const props = __props;
        watch(() => props.courseId, () => loadModule());
        const myLearningSvc = new myLearningService();
        const applicationSvc = new applicationService();
        const authSvc = new authService();
        const selectedCourse = ref();
        const courseTracking = ref();
        const retrievedActivities = ref();
        const invalidSelectedCourse = ref();
        const moduleComplete = ref(false);
        const callbackWindow = window;
        let userProfile;
        let userDetails;
        function getStaffNumber() {
            extendMyNexusSession();
            return userDetails?.employeeId ?? 0;
        }
        function getProgress() {
            extendMyNexusSession();
            if (courseTracking.value != null) {
                const progress = new Map();
                progress.set("Module", courseTracking.value.progress.moduleComplete);
                courseTracking.value.progress.sections.forEach(section => progress.set(section.sectionName, section.isComplete));
                return Object.fromEntries(progress);
            }
            return {};
        }
        function getFirstName() {
            extendMyNexusSession();
            return userProfile?.userName.substring(0, userProfile.userName.indexOf(" ")) ?? "";
        }
        function getLastName() {
            extendMyNexusSession();
            return userProfile?.userName.substring(userProfile.userName.indexOf(" ") + 1) ?? "";
        }
        function recordEvent(sectionNumber, verb, eventInformation, eventValue) {
            extendMyNexusSession();
            if (selectedCourse.value) {
                const newEvent = {
                    employeeId: getStaffNumber(),
                    courseContentId: selectedCourse.value.courseContentId,
                    sectionNumber,
                    verb,
                    eventInformation,
                    eventValue
                };
                myLearningSvc.addMyLearningEvent(selectedCourse.value.courseId, selectedCourse.value.courseContentId, newEvent)
                    .then(() => {
                    if (courseTracking.value) {
                        const currentIndex = courseTracking.value?.events.findIndex(mlEvent => mlEvent.sectionNumber == sectionNumber && mlEvent.verb == verb);
                        if (currentIndex >= 0) {
                            courseTracking.value.events[currentIndex] = newEvent;
                        }
                        else {
                            courseTracking.value?.events.push(newEvent);
                        }
                        if (newEvent.verb == "complete") {
                            const progressSection = courseTracking.value.progress.sections.find(section => section.sectionNumber == newEvent.sectionNumber);
                            if (progressSection) {
                                progressSection.isComplete = true;
                            }
                        }
                    }
                });
            }
        }
        function recordPageEvent(section, eventInfo, pageNo) {
            extendMyNexusSession();
            recordEvent(section, "page", eventInfo, pageNo.toString());
        }
        function retrieveEvent(section, verb) {
            extendMyNexusSession();
            return myLearningSvc.myLearningJsonConverter(courseTracking.value?.events.find(mlEvent => mlEvent.sectionNumber == section && mlEvent.verb == verb));
        }
        function retrievePageEvent(section) {
            extendMyNexusSession();
            return retrieveEvent(section, "page");
        }
        function recordActivity(courseId, employeeId, activityCode, verb, activityValue) {
            extendMyNexusSession();
            if (selectedCourse.value) {
                const newActivity = {
                    courseId: selectedCourse.value.courseId,
                    employeeId: employeeId,
                    activityCode,
                    verb,
                    activityValue
                };
                myLearningSvc.addMyLearningActivity(selectedCourse.value.courseId, selectedCourse.value.courseContentId, newActivity)
                    .then(() => {
                    if (courseTracking.value?.activities) {
                        const currentIndex = courseTracking.value.activities.findIndex(activity => activity.employeeId == newActivity.employeeId && activity.activityCode == newActivity.activityCode && activity.verb == newActivity.verb);
                        if (currentIndex >= 0) {
                            courseTracking.value.activities[currentIndex] = newActivity;
                        }
                        else {
                            courseTracking.value.activities.push(newActivity);
                        }
                    }
                });
            }
        }
        function retrieveActivity(courseId, employeeId, activityCode, verb) {
            extendMyNexusSession();
            if (employeeId == getStaffNumber() && courseTracking.value && courseId == selectedCourse.value?.courseId) {
                return myLearningSvc.myLearningJsonConverter(courseTracking.value.activities.find(mlEvent => mlEvent.activityCode == activityCode && mlEvent.verb == verb));
            }
            else {
                if (!retrievedActivities.value) {
                    retrievedActivities.value = [];
                }
                if (!retrievedActivities.value.some(activity => activity.employeeId == employeeId && activity.courseId == courseId)) {
                    retrievedActivities.value = retrievedActivities.value.concat(myLearningSvc.getMyLearningActivities(employeeId, courseId, 0));
                }
                return myLearningSvc.myLearningJsonConverter(retrievedActivities.value.find(mlEvent => mlEvent.activityCode == activityCode && mlEvent.verb == verb));
            }
        }
        function recordScore(sectionNumber, scoreAchieved, scoreMaximum, eventInformation) {
            extendMyNexusSession();
            if (selectedCourse.value) {
                const newEvent = {
                    employeeId: getStaffNumber(),
                    courseContentId: selectedCourse.value.courseContentId,
                    verb: "score",
                    scoreAchieved,
                    scoreMaximum,
                    sectionNumber,
                    eventInformation
                };
                myLearningSvc.addMyLearningEvent(selectedCourse.value.courseId, selectedCourse.value.courseContentId, newEvent)
                    .then(() => {
                    if (courseTracking.value) {
                        const currentIndex = courseTracking.value?.events.findIndex(mlEvent => mlEvent.sectionNumber == sectionNumber && mlEvent.verb == "score");
                        if (currentIndex >= 0) {
                            courseTracking.value.events[currentIndex] = newEvent;
                        }
                        else {
                            courseTracking.value?.events.push(newEvent);
                        }
                    }
                });
            }
        }
        function getEmployee(employeeId) {
            extendMyNexusSession();
            const employeeDetail = myLearningSvc.getEmployeeDetail(employeeId);
            return myLearningSvc.myLearningJsonConverter(employeeDetail);
        }
        function completeModule() {
            extendMyNexusSession();
            leaveModule(true);
        }
        function checkModuleCompletion(courseId, employeeId) {
            extendMyNexusSession();
            return myLearningSvc.getProgressSynchronous(courseId, employeeId).moduleComplete;
        }
        function loadModule() {
            extendMyNexusSession();
            selectedCourse.value = null;
            courseTracking.value = null;
            retrievedActivities.value = null;
            invalidSelectedCourse.value = null;
            const courseId = parseInt(props.courseId);
            if (courseId) {
                Promise.all([
                    applicationSvc.getProfile()
                        .then(prof => {
                        userProfile = prof;
                    }),
                    authSvc.getUser()
                        .then(usr => {
                        userDetails = usr;
                        if (props.employeeId != userDetails?.employeeId?.toString()) {
                            authSvc.logout();
                        }
                    })
                ]).then(() => {
                    return myLearningSvc.getActiveCourse();
                })
                    .then((activeCourse) => {
                    if (activeCourse && activeCourse.courseId != courseId) {
                        myLearningSvc.getCourseDetails(activeCourse.courseId)
                            .then(course => invalidSelectedCourse.value = course);
                    }
                    else {
                        myLearningSvc.openMyLearningCourse(courseId)
                            .then(course => {
                            if (course) {
                                selectedCourse.value = course;
                                return myLearningSvc.getCourseTracking(course.courseId, course.courseContentId);
                            }
                        })
                            .then(tracking => courseTracking.value = tracking);
                    }
                });
            }
        }
        function switchModule() {
            extendMyNexusSession();
            router.push({ name: "completeModule", params: { "courseId": invalidSelectedCourse.value?.courseId } });
        }
        function leaveModule(completeModule) {
            extendMyNexusSession();
            callbackWindow.CheckModuleCompletion = undefined;
            callbackWindow.CompleteModule = undefined;
            callbackWindow.GetEmployee = undefined;
            callbackWindow.GetFirstName = undefined;
            callbackWindow.GetLastName = undefined;
            callbackWindow.GetProgress = undefined;
            callbackWindow.GetStaffNumber = undefined;
            callbackWindow.RecordActivity = undefined;
            callbackWindow.RecordScore = undefined;
            callbackWindow.RecordEvent = undefined;
            callbackWindow.RecordPageEvent = undefined;
            callbackWindow.RetrieveActivity = undefined;
            callbackWindow.RetrieveEvent = undefined;
            callbackWindow.RetrievePageEvent = undefined;
            myLearningSvc.closeMyLearningCourse(completeModule)
                .then(() => {
                if (completeModule) {
                    moduleComplete.value = true;
                }
                else {
                    closeWindow();
                }
            });
        }
        function closeWindow() {
            extendMyNexusSession();
            window.opener?.postMessage("closing-course", "*");
            window.close();
        }
        function extendMyNexusSession() {
            window.opener?.postMessage("extend-session", "*");
        }
        onMounted(() => {
            loadModule();
            callbackWindow.CheckModuleCompletion = checkModuleCompletion;
            callbackWindow.CompleteModule = completeModule;
            callbackWindow.GetEmployee = getEmployee;
            callbackWindow.GetFirstName = getFirstName;
            callbackWindow.GetLastName = getLastName;
            callbackWindow.GetProgress = getProgress;
            callbackWindow.GetStaffNumber = getStaffNumber;
            callbackWindow.RecordActivity = recordActivity;
            callbackWindow.RecordScore = recordScore;
            callbackWindow.RecordEvent = recordEvent;
            callbackWindow.RecordPageEvent = recordPageEvent;
            callbackWindow.RetrieveActivity = retrieveActivity;
            callbackWindow.RetrieveEvent = retrieveEvent;
            callbackWindow.RetrievePageEvent = retrievePageEvent;
        });
        return (_ctx, _cache) => {
            return (selectedCourse.value && courseTracking.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    (!moduleComplete.value && !courseTracking.value.disabledDueToAbsence)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createElementVNode("div", _hoisted_3, [
                                _createElementVNode("div", _hoisted_4, [
                                    _createVNode(BaseButton, {
                                        "bootstrap-icon": "box-arrow-left",
                                        class: "mb-1",
                                        onClick: _cache[0] || (_cache[0] = () => leaveModule(false))
                                    }, {
                                        default: _withCtx(() => [
                                            _hoisted_5
                                        ]),
                                        _: 1
                                    })
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_6, [
                                _createElementVNode("div", _hoisted_7, [
                                    _createElementVNode("iframe", {
                                        id: "module_frame",
                                        src: `/myLearning/courseContent/${selectedCourse.value?.courseId}/${selectedCourse.value?.startPage}`
                                    }, null, 8, _hoisted_8)
                                ])
                            ])
                        ]))
                        : (moduleComplete.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createElementVNode("div", _hoisted_10, [
                                    _hoisted_11,
                                    _createElementVNode("div", _hoisted_12, [
                                        _createElementVNode("h3", null, "You have successfully completed " + _toDisplayString(selectedCourse.value.courseTitle), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_13, [
                                        _hoisted_14,
                                        _createVNode(BaseButton, {
                                            "bootstrap-icon": "check-lg",
                                            class: "mb-2",
                                            onClick: _cache[1] || (_cache[1] = () => closeWindow())
                                        }, {
                                            default: _withCtx(() => [
                                                _hoisted_15
                                            ]),
                                            _: 1
                                        })
                                    ])
                                ])
                            ]))
                            : (courseTracking.value.disabledDueToAbsence)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                    _createElementVNode("div", _hoisted_17, [
                                        _hoisted_18,
                                        _createElementVNode("div", _hoisted_19, [
                                            _hoisted_20,
                                            _hoisted_21,
                                            _createVNode(BaseButton, {
                                                "bootstrap-icon": "check-lg",
                                                class: "mb-2",
                                                onClick: _cache[2] || (_cache[2] = () => closeWindow())
                                            }, {
                                                default: _withCtx(() => [
                                                    _hoisted_22
                                                ]),
                                                _: 1
                                            })
                                        ])
                                    ])
                                ]))
                                : _createCommentVNode("", true)
                ]))
                : (invalidSelectedCourse.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                        _createElementVNode("div", _hoisted_24, [
                            _createElementVNode("div", _hoisted_25, [
                                _createElementVNode("div", _hoisted_26, [
                                    _createElementVNode("h1", null, "It appears you are currently working on the module \"" + _toDisplayString(invalidSelectedCourse.value.courseTitle) + "\".", 1)
                                ]),
                                _createElementVNode("div", _hoisted_27, [
                                    _hoisted_28,
                                    _hoisted_29,
                                    _createElementVNode("div", _hoisted_30, [
                                        _createVNode(BaseButton, {
                                            "bootstrap-icon": "play-circle",
                                            class: "mb-2",
                                            onClick: _cache[3] || (_cache[3] = () => switchModule())
                                        }, {
                                            default: _withCtx(() => [
                                                _hoisted_31
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(BaseButton, {
                                            "bootstrap-icon": "arrow-clockwise",
                                            class: "mb-2",
                                            onClick: _cache[4] || (_cache[4] = () => loadModule())
                                        }, {
                                            default: _withCtx(() => [
                                                _hoisted_32
                                            ]),
                                            _: 1
                                        })
                                    ])
                                ])
                            ])
                        ])
                    ]))
                    : _createCommentVNode("", true);
        };
    }
});
