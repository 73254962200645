export const httpClient = {
    get,
    getSynchronous,
    getFile,
    post,
    put,
    delete: _delete,
    patch
};
import { formatISO } from "date-fns";
Date.prototype.toJSON = function () {
    return formatISO(this, { representation: "date" });
};
async function get(request) {
    let url = request.url;
    if (request.query) {
        const params = new URLSearchParams(request.query).toString();
        url = url.concat(url.includes("?") ? "&" : "?", params);
    }
    const headers = new Headers();
    headers.append("X-Requested-With", "XMLHttpRequest");
    headers.append("Content-Type", "application/json");
    const requestOptions = {
        method: "GET",
        cache: "no-store",
        headers,
    };
    return handleResponse(await fetch(url, requestOptions));
}
function getSynchronous(request) {
    let url = request.url;
    if (request.query) {
        const params = new URLSearchParams(request.query).toString();
        url = url.concat(url.includes("?") ? "&" : "?", params);
    }
    const ajaxRequest = new XMLHttpRequest();
    let response = { title: "Request not run", isProblemDetails: true };
    ajaxRequest.onreadystatechange = function () {
        if (ajaxRequest.readyState === XMLHttpRequest.DONE) {
            const result = JSON.parse(ajaxRequest.responseText, jsonReviver);
            if (ajaxRequest.status === 200) {
                response = result;
            }
            else if (result.status !== undefined) {
                response = result;
                response.isProblemDetails = true;
            }
            else {
                response = { title: ajaxRequest.statusText, status: ajaxRequest.status, isProblemDetails: true };
            }
        }
    };
    ajaxRequest.open("GET", url, false);
    ajaxRequest.setRequestHeader("Cache-Control", "no-cache");
    ajaxRequest.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    ajaxRequest.setRequestHeader("Content-Type", "application/json");
    ajaxRequest.send();
    return response;
}
async function getFile(request) {
    let url = request.url;
    if (request.query) {
        const params = new URLSearchParams(request.query).toString();
        url = url.concat(url.includes("?") ? "&" : "?", params);
    }
    const headers = new Headers();
    headers.append("X-Requested-With", "XMLHttpRequest");
    headers.append("Content-Type", "application/json");
    const requestOptions = {
        headers,
    };
    const response = await fetch(url, requestOptions);
    if (response.ok) {
        return Promise.resolve();
    }
    return Promise.reject();
}
async function post(request) {
    const headers = new Headers();
    headers.append("X-Requested-With", "XMLHttpRequest");
    headers.append("Content-Type", "application/json");
    const requestOptions = {
        method: "POST",
        headers,
        body: JSON.stringify(request.body)
    };
    return handleResponse(await fetch(request.url, requestOptions));
}
async function put(request) {
    let url = request.url;
    if (request.resourceId != undefined && !url.includes(`/${request.resourceId}`)) {
        url += !url.endsWith("/") ? `/${request.resourceId}` : request.resourceId;
    }
    const headers = new Headers();
    headers.append("X-Requested-With", "XMLHttpRequest");
    headers.append("Content-Type", "application/json");
    const requestOptions = {
        method: "PUT",
        headers,
        body: JSON.stringify(request.body),
    };
    return handleResponse(await fetch(url, requestOptions));
}
async function patch(request) {
    let url = request.url;
    if (request.resourceId != null && !url.includes(`/${request.resourceId}`)) {
        url += !url.endsWith("/") ? `/${request.resourceId}` : request.resourceId;
    }
    const headers = new Headers();
    headers.append("X-Requested-With", "XMLHttpRequest");
    headers.append("Content-Type", "application/json");
    const requestOptions = {
        method: "PATCH",
        headers,
        body: JSON.stringify(request.body),
    };
    return handleResponse(await fetch(url, requestOptions));
}
async function _delete(request) {
    let url = request.url;
    if (request.resourceId != undefined && !url.includes(`/${request.resourceId}`)) {
        url += !url.endsWith("/") ? `/${request.resourceId}` : request.resourceId;
    }
    const headers = new Headers();
    headers.append("X-Requested-With", "XMLHttpRequest");
    headers.append("Content-Type", "application/json");
    const requestOptions = {
        method: "DELETE",
        headers,
        body: JSON.stringify(request.body)
    };
    return handleResponse(await fetch(url, requestOptions));
}
async function handleResponse(response) {
    let text = await response.text();
    if (!text && response.ok) {
        text = "{}";
    }
    let data;
    try {
        data = text && JSON.parse(text, jsonReviver);
    }
    catch (e) {
        throw "Invalid JSON string. Please ensure controller returns valid JSON string not Raw text";
    }
    if (!response.ok) {
        const error = data || { message: response.statusText, status: response.status, isProblemDetails: true };
        return Promise.reject(error);
    }
    return data;
}
function jsonReviver(key, value) {
    if (typeof value === "string") {
        const check = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d/.exec(value);
        if (check) {
            return new Date(value);
        }
    }
    return value;
}
